<template>
    <div id="rooms-info">
        <b-container fluid>
          <b-modal id="show-room-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('room-status')" size="sm" hide-header-close @ok="udateRoomServicese">
            <b-form class="gap-5">
              <b-row>
                <b-col sm="12">
                  <b-form-select v-model="showRoomPayload.type" :options="roomsTypes"></b-form-select>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <b-modal id="room-services" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('room-services')" size="xl" @ok="udateRoomServicese">
            <b-form class="gap-5">
              <b-row>
                <b-col sm="12" v-for="(service,index) in services.data" :key="index">
                  <div class="swich-container border border-primary rounded mb-1  py-2 px-3 bg-light d-flex align-items-center justify-content-between">
                    <span>
                      <i :class="service.icon"></i>
                      {{ service[`${$i18n.locale}_name`] }}
                    </span>
                    <b-form-checkbox v-model="serviceIds" :value="service.id" :name="service[`${$i18n.locale}_name`]" switch></b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <div class="add-image-section">
          <b-modal id="add-img-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('upload-image')" size="xl" @ok="uploadRoomImage">
            <b-form class="gap-5">
              <b-row>
                <b-col sm="12" md="6" lg="3" v-for="(image,index) in hotelImgs" :key="index">
                  <div class="position-relative">
                    <input v-model="imagePayload.images" :value="image.id" type="checkbox" class="position-absolute"
                    style="top: 10px; left: 10px; width: 18px; height: 18px;" >
                  <img class="img-fluid mb-1 rounded border" :src="image.image_path" alt="">
                </div>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
            <div class="room-statistic pb-3 mb-5 border-bottom">
                <b-row>
                    <b-col md="6" class="mb-3" lg="3" v-for="(stats,index) in roomState" :key="index">
                      <iq-card>
                        <template v-slot:body>
                          <div class="d-flex rounded align-items-center mb-3 mb-lg-0">
                                <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"> <i :class="stats.icon"></i></div>
                                <div class="text-left">
                                <h6>{{$t(stats.title)}}</h6>
                                <p class="mb-0">{{room[stats.key]}}</p>
                                </div>
                            </div>
                        </template>
                        </iq-card>
                    </b-col>
                    <b-col md="6" class="mb-3" lg="3">
                      <iq-card>
                        <template v-slot:body>
                          <div class="d-flex rounded align-items-center mb-3 mb-lg-0">
                                <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"> <i class="fas fa-eye"></i></div>
                                <div class="text-left">
                                  <h6>{{$t('room-status')}}</h6>
                                  <p class="mb-0 text-success" v-if="room.is_available">{{$t('available')}}</p>
                                  <p class="mb-0 text-danger" v-else> {{$t('unavailable')}} </p>
                                </div>
                            </div>
                        </template>
                        </iq-card>
                    </b-col>
                </b-row>
            </div>
            <b-row>
              <b-col cols="8">
                <div class="sub-rooms">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('nav.rooms')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button :variant="room.is_available?'danger':'success'" class="" @click="changeRoomStatus()">
                        <span v-if="room.is_available">{{ $t('switch-to-unavailable') }}</span>
                        <span v-else>{{ $t('switch-to-available') }}</span>
                      </b-button>
                      <b-button variant="primary" class="mx-2" @click="$router.push(`/add-rooms/${$route.params.id}`)">{{ $t('edit-room-info') }}</b-button>
                      <b-button variant="success" class="mx-2"  @click="$bvModal.show('room-services')">{{ $t('room-services') }}</b-button>
                      <b-button variant="info"  @click="$router.push(`/rooms-price/${$route.params.id}`)">{{ $t('room-price') }}</b-button>
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col sm="6" md="3" class="mb-2">
                          <div @click="showAddModale" style="min-height: 200px; height: 100%;" class="add-room-image border border-dashed border-2 rounded d-flex align-items-center justify-content-center flex-column">
                            <i class="ri-add-line"></i>
                            <span>
                              {{ $t('upload-image') }}
                            </span>
                          </div>
                        </b-col>
                        <b-col sm="6" md="3" class="mb-2" v-for="(image,index) in room.images" :key="index">

                          <!-- <b-button variant="danger" @click="removeImage(img.id)" class="position-absolute" style="top: 15px; left: 15px;"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                          <img class="img-fluid mb-1 rounded border" :src="image.image_path" alt="">
                        </b-col>
                      </b-row>
                      <b-row v-if="room.images && room.images.length < 5">
                        <b-col cols="12" class="text-center">
                          {{ $t('room-image-hint') }}
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </div>
              </b-col>
              <b-col cols="4">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">active calender</h4>
                  </template>
                  <template v-slot:body>
                    <FullCalendar ref="calendar" :options='calendarOptions'>
                    </FullCalendar>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'// for selectable
import dayGridPlugin from '@fullcalendar/daygrid'
export default {
  data () {
    return {
      room_image_data: [],
      serviceIds: [],
      roomsTypes: [
        {
          text: this.$t('show'),
          value: 0
        },
        {
          text: this.$t('hide'),
          value: 1
        }
      ],
      imagePayload: {
        images: []
      },
      showRoomPayload: {
        room_id: this.$route.params.id,
        type: 1,
        days: []
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        selectable: true,
        select: this.handelSelect,
        initialView: 'dayGridMonth',
        weekends: true,
        // eventClick: this.editInit,
        // datesSet: this.nextBtnEvent,
        events: [],
        eventColor: '#f8f7fd',
        showNonCurrentDates: false
        // validRange: { start: today }
      },
      roomState: [
        {
          title: 'room-name',
          icon: 'fas fa-sort-amount-down',
          key: 'name'
        },
        {
          title: 'views',
          icon: 'fas fa-eye',
          key: 'total_views'
        },
        {
          title: 'adult-number',
          icon: 'fas fa-male',
          key: 'adult_number'
        },
        {
          title: 'child-number',
          icon: 'fas fa-child',
          key: 'child_number'
        },
        {
          title: 'total-reverse',
          icon: 'fas fa-ticket-alt',
          key: 'total_reserve'
        },
        {
          title: 'bathroom-Number',
          icon: 'fas fa-sink',
          key: 'bathroom_number'
        },
        {
          title: 'kitchen-Number',
          icon: 'fas fa-cocktail',
          key: 'kitchen_number'
        }
      ]
    }
  },
  components: {
    FullCalendar
  },
  methods: {
    getDatesBetween (startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      const dates = []

      // Check if end date is before start date
      if (end < start) {
        return []
      }

      // Loop through dates
      const current = new Date(start)
      // eslint-disable-next-line no-unmodified-loop-condition
      while (current <= end) {
        dates.push(this.formatDate(current))
        current.setDate(current.getDate() + 1) // Move to the next day
      }
      this.showRoomPayload.days = dates
    },
    formatDate (date) {
      // Format date to YYYY-MM-DD (you can adjust this format)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    showAddModale () {
      this.$bvModal.show('add-img-modal')
    },
    handelSelect (selectInfo) {
      this.getDatesBetween(selectInfo.startStr, selectInfo.endStr)
      this.$bvModal.show('show-room-modal')
    },
    udateRoomServicese () {
      this.addRoomServices({
        id: this.$route.params.id,
        servicesIds: {
          serviceIds: this.serviceIds
        }
      }).then(() => {
        this.getRoom(this.$route.params.id)
      })
    },
    uploadRoomImage () {
      var data = {}
      this.imagePayload.images.forEach((el, index) => {
        data[`images[${index}]`] = el
      })
      data.room_id = this.$route.params.id
      this.setRoomImage(this.returnData(data)).then(() => {
        this.getRoomImage(this.$route.params.id)
        this.getRoom(this.$route.params.id)
      })
    },
    changeRoomStatus () {
      this.confermMsg(() => {
        this.ChangeAvailabelRoom(this.$route.params.id).then(() => {
          this.getRoom(this.$route.params.id).then(() => {
            this.room.services.map(el => {
              this.serviceIds.push(el.id)
            })
          })
        })
      })
    }
  },
  mounted () {
    this.getRoom(this.$route.params.id).then(() => {
      this.room.services.map(el => {
        this.serviceIds.push(el.id)
      })
    })
    this.getRooms()
    this.getRoomImage(this.$route.params.id)
    this.getHotel()
    this.getHotelImg()
    this.getServicesRoom()
  }

}
</script>
<style>

.rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.rooms-card .info {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}

</style>
